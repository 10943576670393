<template>
    <Layout>
        <b-card>
            <div class="card-body" id="page">
                <div class="row">
                    <div class="col-md-4 mb-3">
                        <label class="form-label">Mentoria <span
                            class="text-danger">*</span></label>
                        <select class="form-control form-select" v-model="formData.mentoring_id" required>
                            <option value="">Selecionar</option>
                            <option :value="mentoring.id" v-for="mentoring in mentorings"
                                    :key="`option-mentoring-${mentoring.id}`">
                                {{ mentoring.title }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Código <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Código" class="form-control form-select"
                               v-model="formData.code" required/>
                    </div>

                    <div class="col-md-4 mb-3">
                        <div class="d-flex justify-content-between">
                            <label class="form-label">Desconto <span
                                class="text-danger">*</span></label>
                            <i v-b-tooltip.hover.right="'Valor máximo do desconto deve ser de 50%'"
                               class="fa fa-info-circle ml-2 my-auto"></i>
                        </div>
                        <div class="d-flex">
                            <input type="number" placeholder="Desconto" class="form-control form-select"
                                   v-model="formData.discount" required max="50" style="border-bottom-right-radius: 0; border-top-right-radius: 0"/>
                            <span class="append">%</span>
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Válido a partir <span
                            class="text-danger">*</span></label>
                        <input type="date" placeholder="Desconto" class="form-control form-select"
                               v-model="formData.valid_from" required/>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Válido até <span
                            class="text-danger">*</span></label>
                        <input type="date" placeholder="Desconto" class="form-control form-select"
                               v-model="formData.valid_until"/>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Quantidade <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Quantidade" class="form-control form-select"
                               v-model="formData.amount"/>
                    </div>

                    <div class="col-12 text-end">
                        <button class="btn btn-outline-danger" @click="goBack">Cancelar</button>
                        <button class="btn btn-outline-success ml-2" @click="save">Salvar</button>
                    </div>

                </div>
            </div>
        </b-card>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import http from "@/http";
import store from "@/store";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import {endLoading, startLoading} from "@/components/composables/spinners";


export default {
    components: {
        Layout,
    },

    data() {
        return {
            formData: {
                id: 0,
                mentoring_id: '',
                code: '',
                discount: '',
                valid_from: '',
                valid_until: '',
                amount: '',
            },
            mentorings: []
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },

        save() {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);
            const user = store.state.auth.user;
            const required = {
                mentoring_id: 'Mentoria',
                code: 'Código',
                discount: 'Desconto',
                valid_from: 'Válido a partir',
                valid_until: 'Válido até',
                amount: 'Quantidade',
            }

            if (!ValidateForm(this.$root, this.formData, required)) {
                endLoading('page', 'save');
                this.$store.commit('api/setSpinner', false);
                return;
            }

            const newFormData = toFormData(this.formData);

            newFormData.append('mentor_id', user.id);

            let url = 'coupons';

            if (this.formData.id > 0) {
                newFormData.append('_method', 'PUT');
                url += `/${this.formData.id}`
            }

            http.post(url, newFormData)
                .then(() => {
                    this.$notifySuccess('Cadastro realizado com sucesso!')
                    setTimeout(() => {
                        this.$router.push('/cupons')
                    }, 500)
                })
                .catch(e => {
                    console.error(e);
                    this.$notifyError(e.response.data);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        getData(id) {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('coupons/' + id)
                .then(response => {
                    this.formData = response.data.data[0];
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        getMentorings() {
            const user = store.state.auth.user;
            if (!user) {
                setTimeout(() => {
                    this.getMentorings();
                    return;
                }, 200)
            }
            http.get('mentorings?paginated=false&limit=1000&mentor_id=' + user.id)
                .then(response => {
                    this.mentorings = response.data.data;
                })
        }
    },

    mounted() {
        this.getMentorings();
        const id = this.$route.params.id;
        if (id) this.getData(id);
    }
}
</script>

<style>

.append {
    margin-left: 0.5px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #272C33;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #EDF0F2;
    border-left: none;
    border-radius: 0.25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    box-shadow: inset 0 1px 1px rgba(39, 44, 51, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

</style>
